<template>
	<div class="rootDiv">
		<div class="retrieve">
			<div class="searchDiv">
				<div class="flex flex-jb flex-ac">
					<div class="flex flex-ac">
						<span class="fs-16 flex1">系列名称：</span>
						<el-input class="w-150" v-model="retrieveForm.seriesName" clearable></el-input>
					</div>
					<div class="flex flex-ac">
						<span class="fs-16 flex1">商品名称：</span>
						<el-input class="w-150" v-model="retrieveForm.detailName" clearable></el-input>
					</div>
					<div class=" flex flex-ac">
						<span class="fs-16 flex-1">类型：</span>
						<el-select class="w-150" v-model="retrieveForm.goodSeriesType" clearable placeholder="请选择">
							<el-option v-for="item in goodSeriesTypes" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="flex flex-ac">
						<span class="fs-16 flex-1 ">系列状态：</span>
						<el-select class="w-100" v-model="retrieveForm.seriesPattern" clearable>
							<el-option v-for="item in sericeTypeOptions" :key="item.label" :label="item.key"
								:value="item.value"></el-option>
						</el-select>
					</div>
					<div class="flex flex-ac">
						<span class="fs-16 flex-1">是否上架：</span>
						<el-select class="w-100" v-model="retrieveForm.shelves" clearable placeholder="请选择">
							<el-option v-for="item in shelves" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="fs-16 pointer">
						<span class="fs-16 w-150">排序：</span>
						<el-select class="w-150" v-model="retrieveForm.sortBy" clearable placeholder="请选择">
							<el-option v-for="item in sortTypes" :key="item.value" :label="item.key" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div label="" class="mr-10">
						<el-button type="primary" @click="submitSearch">查询</el-button>
					</div>
					<div class="mr-10">
						<el-button type="primary" @click="transferbtn">传递多个</el-button>
					</div>
					<div class="fs-16 fc-6b9eff pointer" @click="addList">
						<i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
					</div>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				@row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }"
				@selection-change="handleSelectionChange">
				<template v-slot:img="scope">
					<img :src="scope.row.coverImg" alt="" class="smallImg" v-if="scope.row.coverImg">
				</template>
			</EleTable>
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 一番赏详情 -->
		<reward-details ref="reward-details" @refresh="getTableData"></reward-details>
		<limit-reward ref="limitReward"></limit-reward>
		<!-- 修改记录 -->
		<el-dialog title="修改记录" :visible.sync="dialogUseVisible">
			<div class="dialog-content">
				<EleTable ref="recordRef" slot="record" :tableData="recordData" :columns="recordColumns" height="100%"
					v-loading="recLoading">
				</EleTable>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogUseVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogUseVisible = false">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 复制弹层 -->
		<el-dialog title="复制" :visible.sync="dialogFormVisible" style="width: 50%;margin-left: 25%;">
			<el-form label-width="100px">
				<el-form-item label="系列名称" label-width="100px">
					<el-input v-model="copyDialog.seriesName" placeholder="木有可复制的数据啦" style=" width: 250px;"></el-input>
				</el-form-item>
				<el-form-item label="系列状态" label-width="100px">
					<el-select class="w-250" v-model="copyDialog.seriesPattern" placeholder="木有可复制的数据啦" clearable>
						<el-option v-for="item in sericeTypeOptions" :key="item.key" :label="item.key"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="copySubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 传递数据 -->
		<el-dialog title="传递数据" :visible.sync="transferVisible">
			<div class="dialog-content">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px">
					<el-form-item label="选择平台" prop="platformUrl" v-if="'platformUrl' in ruleForm"
						:rules="{required: true, message: '请选择平台', trigger: 'change'}">
						<el-select v-model="ruleForm.platformUrl" placeholder="请选择">
							<el-option v-for="item in platform" :key="item.value" :label="item.key" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="transferVisible = false">取 消</el-button>
				<el-button type="primary" @click="certain">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'drawsGood',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			"reward-details": (resolve) => require(["@/views/modules/goods/rewardDetails"], resolve),
			"limit-reward": (resolve) => require(["@/views/modules/goods/limitReward"], resolve),
		},
		computed: {
			...mapState('menu', {
				'goodSeriesTypes': state => state.dictList ? state.dictList.good_series_type : [], //优惠劵类型
				'shelves': state => state.dictList ? state.dictList.shelves : [], //是否上下架
			})
		},
		data() {
			return {
				copyDialog: {
					id: '',
					seriesName: '',
					seriesPattern: '',
				},
				retrieveForm: {
					seriesName: '',
					seriesPattern: '',
					goodSeriesType: '',
					shelves: '',
					sortBy: '',
				},
				sortTypes: [{
					value: 0,
					key: '创建时间正序'
				}, {
					value: 1,
					key: '创建时间倒序'
				}, {
					value: 2,
					key: '排序字段正序'
				}, {
					value: 3,
					key: '排序字段倒序'
				}, {
					value: 4,
					key: '修改时间正序'
				}, {
					value: 5,
					key: '修改时间倒序'
				}],
				sericeTypeOptions: [{
					value: 1,
					key: '排队'
				}, {
					value: 0,
					key: '竞技'
				}],
				configData: '',
				tabLoading: false,
				recLoading: false,
				columns: [{
					type: 'selection',
					align: 'center',
					width: "30",
					fixed: 'left',
				}, {
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center',
					fixed: 'left',
					index: this.indexMethod
				}, {
					columnType: 'custom',
					label: '封面图',
					prop: 'img',
					align: 'left',
					width: '80'
				}, {
					label: '系列名称',
					prop: 'seriesName',
					align: 'left',
					width: '250',
					'show-overflow-tooltip': true
				}, {
					label: '价格',
					prop: 'soldPrice',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '正式',
					prop: 'userSoldTotal',
					align: 'center',
					'show-overflow-tooltip': true
				}, {
					label: '测试',
					prop: 'testUserSoldTotal',
					align: 'center',
					'show-overflow-tooltip': true
				}, {
					columnType: 'fun',
					label: '系列分类',
					prop: 'goodSeriesType',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.goodSeriesTypes.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					label: '创建时间',
					prop: 'createDate',
					width: '200',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '发货日期',
					prop: 'deliverDate',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '系列状态',
					prop: 'seriesPattern',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.sericeTypeOptions.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					columnType: 'fun',
					label: '是否上架',
					prop: 'shelves',
					align: 'left',
					'show-overflow-tooltip': true,
					formatData: (e) => {
						let ele = this.shelves.find(ele => ele.value == e)
						return ele ? ele.key : e
					}
				}, {
					columnType: 'button',
					label: '操作',
					align: 'right',
					fixed: 'right',
					width: '230',
					buttons: [
					// 	{
					// 	type: 'text',
					// 	text: '同步',
					// 	fn: this.synchronousList,
					// 	hidden: (e) => {
					// 		return e.parent != 0
					// 	}
					// }, 
					{
						type: 'text',
						text: '限时兑换',
						fn: this.limitList
					}, {
						type: 'text',
						text: '传递',
						fn: this.transferList
					}, {
						type: 'text',
						text: '编辑',
						fn: this.editList
					}, {
						type: 'dropdown',
						text: '更多',
						items: [{
								text: '复制',
								fn: this.copyList
							},
							{
								text: '记录',
								fn: this.recordList
							},
							{
								text: '删除',
								fn: this.deleteList
							}
						]
					}]
				}, ],
				tableData: [],
				dialogFormVisible: false, //复制弹框
				dialogUseVisible: false, //修改记录弹框
				recordData: [], //修改记录
				transferVisible: false,
				ruleForm: {
					platformUrl: ''
				},
				transferData: '',
				selectData: '',
				ids: '', //选择的系列id
				platform: [{
					// value: 'http://192.168.3.122:8083/manghe/',
					value: 'https://manghe.hzhxyqchy.com/manghe/',
					key: '乐芒抽盒机'
				}, {
					value: 'https://sauce.hzhxyqchy.com/ouqi/',
					key: '欧气酱潮玩'
				}, {
					value: 'https://bobo.hzhxyqchy.com/bob/',
					key: '啵啵抽盒机'
				}, {
					value: 'https://gull.hzkuqu.com/gull/',
					key: '一只欧抽盒机'
				}, {
					value: 'https://mart.hzhxyqchy.com/mart/',
					key: '趣丸抽盒机'
				}, {
					value: 'https://donut.hzhxyqchy.com/donut/',
					key: '甜甜圈抽盒机'
				},{
					value: 'https://kall.hzhxyqchy.com/kall/',
					key: '咕噜噜抽盒机'
				}
				],
				recordColumns: [{
					type: 'index',
					label: '序号',
					width: '60',
					align: 'center',
					index: this.indexMethod
				}, {
					label: '修改日期',
					prop: 'createDate',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '修改用户',
					prop: 'username',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '系列名称',
					prop: 'seriesName',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '发货日期',
					prop: 'deliverDate',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '系列状态',
					prop: 'seriseType',
					align: 'left',
					'show-overflow-tooltip': true
				}, {
					label: '价格',
					prop: 'soldPrice',
					align: 'left',
					'show-overflow-tooltip': true
				}, ],
			}
		},
		mounted() {
			this.getTableData()
			this.findSystemConfig()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//新增列表
			addList() {
				console.log("点击了新增")
				this.$refs['reward-details'].init('', this.tableData)
			},
			//导出
			exportExcel() {},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/goodSeries/finGoodSeriesAll', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						seriesGoodsType: 0,
						...this.retrieveForm,
					}
				}).then(({
					data: result
				}) => {
					console.log(result.data)
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				}).catch(err => {
					this.tabLoading = false
				})
			},
			limitList(row) {
				this.$refs['limitReward'].init(row.id)
			},
			//复制弹框
			copyList(row) {
				this.dialogFormVisible = true
				this.copyDialog = JSON.parse(JSON.stringify(row))
			},
			// 确认复制
			copySubmit(row) {
				this.$http.post('/goodSeries/copySeriesById', {
					...this.copyDialog
				}).then(({
					data: result
				}) => {
					this.$message({
						message: '复制成功',
						type: 'success'
					})
					this.dialogFormVisible = false
				}).catch((err) => {
					this.dialogFormVisible = false
				})
			},
			//选择传递数据
			handleSelectionChange(val) {
				this.selectData = val
			},
			// 传递单个
			transferList(row) {
				this.transferData = row
				this.transferVisible = true
				this.recLoading = true
			},
			//传递多个
			transferbtn() {
				let selectData = this.selectData
				if (selectData.length > 0) {
					this.transferVisible = true
					this.recLoading = true
					let idList = selectData.map(ele => {
						return ele.id
					})
					let ids = idList.join(',')
					this.ids = ids
					// this.certain(ids)
				} else {
					this.$message({
						showClose: true,
						message: '请勾选传递的商品',
						type: 'error'
					});
				}
			},
			//单个传递
			certain() {
				let transferParm = {
					copyUrl: this.ruleForm.platformUrl,
					seriesId: this.ids ? this.ids : this.transferData.id
				}
				// let transfer = JSON.stringify(transferParm)
				this.$http.post('/goodSeries/copySeriesOtheSytById', transferParm).then(res => {
					this.$message({
						message: '传递成功',
						type: 'success'
					})
					this.ruleForm.platformUrl = ''
					this.transferVisible = false
					this.butLoading = false
					this.getTableData()
					// this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			//记录
			recordList(row) {
				console.log("记录", row)
				this.dialogUseVisible = true
				this.recLoading = true
				this.$http.get('/seriesEdit/findSeriesEdit', {
					params: {
						boxType: 5,
						seriesId: row.id,
					}
				}).then(({
					data: result
				}) => {
					this.recordData = result.data
					this.recLoading = false
				}).catch((err) => {
					this.recLoading = false
				})
			},
			//查询基础配置
			findSystemConfig() {
				this.$http.get('/systemConfig/findSystemConfig').then((result) => {
					let configList = result.data.data
					if (configList.length > 0) {
						console.log("基础配置", configList);
						this.configData = configList[0]
					}
				}).catch((err) => {})
			},
			//保存 默认次数
			submitForm(sortOff) {
				console.log(sortOff);
				this.$confirm('您确认调整排序方式？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					let configData = this.configData
					configData.goodSortOff = sortOff ? 0 : 1
					this.$http.post('/systemConfig/editSystemConfig', configData).then((result) => {
						this.$message({
							message: '保存成功',
							type: 'success'
						})
						this.findSystemConfig()
					}).catch((err) => {})
				}).catch(_ => {})
			},
			//编辑
			editList(row) {
				this.$refs['reward-details'].init(row.id)
			},
			//同步
			synchronousList(data) {
				this.$confirm('您确认同步？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/goodSeries/syncSeriesData', {
						id: data.id
					}).then(res => {
						this.$message({
							message: '同步成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//删除
			deleteList(data) {
				this.$confirm('您确认删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post('/goodSeries/removeGoodSeries', {
						id: data.id
					}).then(res => {
						this.getTableData()
						this.$message({
							message: '删除成功',
							type: 'success'
						})
					})
				}).catch(_ => {})
			},
			//跳转详情
			headerRowClick(row) {
				// console.log(row)
			},
		},
	}
</script>
<style lang="less" scoped>
	.dialog-content {
		max-height: 250px;
	}
</style>